// fonts
$font-family: "Poppins", "Poppins-fallback", Arial, sans-serif;
$font-size: 16px;
$line-height: 1.67;
$font-weight-light: 200;
$font-weight-regular: 400;
$font-weight-bold: 600;

// colors
$primary-color: #162745;
$primary-light-color: #f5f7f9;
$secondary-color: #60ba97;
$contrast-color: #fff;
$addons-color: #7a8395;
$gradient-start-color: #00ff86;
$gradient-middle-color: #52a7a9;
$gradient-end-color: #247ca4;

// utility colors
$error-color: #d90e0e;
