@use "../variables" as variable;

.button {
	display: inline-block;
	padding: 19px 50px;
	border: 2px solid variable.$secondary-color;
	border-radius: 10px;
	outline: none;
	transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1), background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
	font-size: 15px;
	font-weight: variable.$font-weight-regular;
	line-height: 1.2;
	letter-spacing: 1.5px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	box-shadow: 0 15px 30px 0 rgba(variable.$secondary-color, 0.24);
	cursor: pointer;
	color: variable.$primary-color;
	background-color: rgba(variable.$secondary-color, 0.07);

	@media print {
		display: none;
	}

	&:hover,
	&:focus,
	&:focus-visible,
	&:active {
		color: variable.$contrast-color;
		background-color: variable.$secondary-color;
	}

	&-medium {
		padding: 14px 35px;
	}

	&-small {
		padding: 4px 15px;
		border-radius: 6px;
		font-size: 11px;
		font-weight: variable.$font-weight-bold;
	}

	&-reverse {
		background-color: variable.$primary-light-color;
	}

	&-text {
		padding: 0;
		border: none;
		font-weight: inherit;
		cursor: pointer;
		background: none;

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			color: variable.$secondary-color;
		}
	}
}
