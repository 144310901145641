.mb-0 {
	margin-bottom: 0;
}

.mb-sm {
	margin-bottom: 20px;
}

.mb-md {
	margin-bottom: 30px;
}

.mb-lg {
	margin-bottom: 40px;
}

.text-center {
	text-align: center;
}

.media-print-hide {
	@media print {
		display: none;
	}
}
