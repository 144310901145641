@use "../variables" as variable;

*,
::before,
::after {
	box-sizing: border-box;
}

html {
	line-height: 1.15;
	-moz-tab-size: 4;
	tab-size: 4;
	-webkit-text-size-adjust: 100%;
}

body {
	margin: 0;
}

hr {
	height: 0;
	color: inherit;
}

b,
strong {
	font-weight: variable.$font-weight-bold;
}

small {
	font-size: 80%;
}

sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

table {
	border-color: inherit;
	text-indent: 0;
}

button,
input,
optgroup,
select,
textarea {
	margin: 0;
	outline: none;
	font-family: inherit;
	font-size: 100%;
	line-height: 1.15;
}

button,
select {
	text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
}

::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

:-moz-focusring {
	outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
	box-shadow: none;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
	height: auto;
}
