@use "../variables" as variable;

html {
	font-size: variable.$font-size;
}

body {
	font-family: variable.$font-family;
	font-weight: variable.$font-weight-regular;
	line-height: variable.$line-height;
	color: variable.$primary-color;
	background-color: variable.$primary-light-color;
}

a {
	-webkit-tap-highlight-color: transparent;
}
