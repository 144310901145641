@use "../variables" as variable;

.input {
	width: 100%;
	padding: 19px;
	border: 2px solid variable.$secondary-color;
	border-radius: 10px;
	transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
	font-size: 15px;
	font-weight: variable.$font-weight-regular;
	line-height: 1.2;
	letter-spacing: 0.75px;
	color: variable.$primary-color;
	background-color: variable.$primary-light-color;

	&:hover,
	&:focus,
	&:active {
		border-color: variable.$addons-color;
	}

	&:focus:invalid {
		border-color: variable.$error-color;
	}
}

.field {
	position: relative;
	margin-bottom: 20px;

	.label {
		position: absolute;
		top: 21px;
		left: 21px;
		display: block;
		padding: 0 10px;
		border-color: transparent;
		transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1), font-size 250ms cubic-bezier(0.4, 0, 0.2, 1), background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
		font-size: 15px;
		font-weight: variable.$font-weight-regular;
		line-height: 1.2;
		letter-spacing: 0.75px;
		cursor: text;
		pointer-events: none;
		background-color: transparent;

		&:hover {
			border-color: variable.$addons-color;
		}
	}

	&.required {
		&::before {
			content: "*";
			position: absolute;
			top: 0;
			left: 100%;
			width: 30px;
			height: 100%;
			font-size: 30px;
			text-align: center;
			color: variable.$error-color;
		}
	}

	.input:not(:placeholder-shown) + .label,
	.input:focus + .label {
		transform: translateY(-27px);
		font-size: 12px;
		background-color: variable.$primary-light-color;
	}
}

.field-checkbox {
	position: relative;
	display: flex;

	&-center {
		justify-content: center;
	}
}

.checkbox {
	position: absolute;
	z-index: -1;
	border: none;
	opacity: 0;
	appearance: none;

	@media print {
		position: relative;
		z-index: 999;
		border: 1px solid variable.$primary-light-color;
		opacity: 1;
		appearance: auto;
		width: 20px;
		height: 20px;
	}

	&:checked:disabled + .label-checkbox::before {
		background-color: variable.$addons-color;
	}

	&:not(:checked):disabled + .label-checkbox::before {
		background-color: variable.$primary-light-color;
	}

	&:checked:not(:disabled) + .label-checkbox::before {
		background-color: variable.$secondary-color;
	}

	&:focus:not(:disabled):not(:checked) + .label-checkbox::before,
	&:hover:not(:disabled):not(:checked) + .label-checkbox::before {
		border-color: variable.$addons-color;
	}

	&:disabled + .label-checkbox::before {
		border-color: variable.$addons-color;
		background-color: variable.$addons-color;
	}
}

.label-checkbox {
	position: relative;
	display: flex;
	flex: 1 1 auto;
	user-select: none;

	@media print {
		display: none;
	}

	&::before {
		content: "";
		display: block;
		flex: 0 0 auto;
		width: 26px;
		height: 26px;
		margin-right: 10px;
		border: 2px solid variable.$secondary-color;
		border-radius: 5px;
		transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
		background-color: variable.$primary-light-color;
	}

	&::after {
		content: "";
		position: absolute;
		top: 6px;
		left: 10px;
		display: block;
		width: 6px;
		height: 11px;
		border: 2px solid variable.$primary-light-color;
		border-top-width: 0;
		border-left-width: 0;
		transform: rotate(45deg);
	}

	&:empty {
		flex: 0 0 auto;

		&::before {
			margin-right: 0;
		}
	}
}
