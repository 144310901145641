@use "../variables" as variable;

.table {
	min-width: auto;
	max-width: 100%;
	border-spacing: 0;
	border-collapse: collapse;

	@media print {
		width: 100%;
	}

	.center {
		text-align: center;
	}

	th,
	td {
		padding: 10px;
		text-align: left;
	}

	td {
		.button {
			margin: 5px;
		}
	}

	thead {
		tr {
			background-color: rgba(variable.$addons-color, 0.1);
		}
	}

	tbody {
		tr {
			background-color: rgba(variable.$addons-color, 0.1);

			&:nth-child(odd) {
				background-color: variable.$primary-light-color;
			}

			&:hover {
				background-color: rgba(variable.$addons-color, 0.4);
			}
		}

		td {
			min-width: 100px;

			@media print {
				min-width: 0;
			}
		}
	}
}

.table-responsive {
	max-width: calc(100vw - 60px);
	overflow-x: auto;

	@media print {
		max-width: none;
		overflow: visible;
		width: 100%;
	}
}
