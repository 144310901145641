@use "../variables" as variable;

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	margin-top: 0;
}

h1,
.h1 {
	font-size: 48px;
	font-weight: variable.$font-weight-light;
	line-height: 1.19;
}

h2,
.h2 {
	font-size: 42px;
	font-weight: variable.$font-weight-light;
	line-height: 1.19;
}

h3,
.h3 {
	font-size: 36px;
	font-weight: variable.$font-weight-light;
	line-height: 1.19;
}

h4,
.h4 {
	font-size: 28px;
	font-weight: variable.$font-weight-regular;
	line-height: 1.18;
}

h5,
.h5 {
	font-size: 18px;
	font-weight: variable.$font-weight-regular;
	line-height: 1.39;
}

h6,
.h6 {
	font-size: 14px;
	font-weight: variable.$font-weight-regular;
	line-height: 1.79;
}
