.layout {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	min-height: 100vh;

	@media print {
		display: block;
	}
}

.layout-authorized {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;
	min-height: 100vh;
}
