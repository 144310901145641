@use "../variables" as variable;

.header {
	background-color: variable.$primary-color;
	padding: 15px 30px;
	color: variable.$contrast-color;
	display: flex;
	align-items: center;

	@media print {
		padding: 0;
		display: block;
		background-color: transparent;
		color: variable.$primary-color;
	}

	h1 {
		margin-bottom: 0;

		@media print {
			text-align: center;
			margin-bottom: 30px;
		}
	}

	div {
		+ div {
			margin-left: auto;
		}
	}
}
