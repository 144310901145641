.wrap {
	width: 100%;
	max-width: 700px;
	margin: auto;
}

.backlink {
	width: 100%;
	max-width: 700px;
	margin: 0 auto 50px;
}
