.wrap {
	width: 100%;
	max-width: 350px;
	margin: auto;
}

//className={"input " + styles.input + " " + styles.inputOverride}
//.input.inputOverride {
//	background-color: red;
//}
